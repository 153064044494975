import * as SSOService from '../services/SSO'

export const ACTIONS = {
  GET_TOKEN_REQUEST: 'GET_TOKEN_REQUEST',
  GET_TOKEN_SUCCESS: 'GET_TOKEN_SUCCESS',
  GET_TOKEN_FAILURE: 'GET_TOKEN_FAILURE',
}

export const GET_TOKEN_REQUEST = () => ({
  type: ACTIONS.GET_TOKEN_REQUEST,
})

export const GET_TOKEN_SUCCESS = (token) => ({
  type: ACTIONS.GET_TOKEN_SUCCESS,
  token,
})

export const GET_TOKEN_FAILURE = (err) => ({
  type: ACTIONS.GET_TOKEN_FAILURE,
  err,
})

export function getDKTToken(code, redirectUri) {
  return (dispatch) => {
    dispatch(GET_TOKEN_REQUEST())

    return SSOService.getDKTToken(code, redirectUri).then(
      (response) => dispatch(GET_TOKEN_SUCCESS(response)),
      (err) => dispatch(GET_TOKEN_FAILURE(err))
    )
  }
}
