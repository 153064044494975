import axiosWrapper from '../helpers/api'
import { ERequestUrl, EMethods } from '../helpers/requests'

export const getDKTToken = (code, redirectUri) =>
  axiosWrapper({
    method: EMethods.get,
    url: ERequestUrl.token,
    params: {
      code: code,
      redirectUri: redirectUri,
    },
  })
